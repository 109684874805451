<template>
  <div :class="['medication', {'disabled': !enabled}]" :style="bgcolor">
    <div class="checkbox">
      <input type="checkbox" v-model="enabled">
    </div>
    <div class="name">
      {{name}}
    </div>
    <div class="nurse-icon" v-if="nurse">
      N
    </div>
  </div>
</template>
<script>
export default {
  props: {
    name: String,
    modelValue: Boolean,
    colors: Array,
    medlist: Array,
    nurse: Boolean,
    id: [String, Number],
  },
  data() {
    return {
    };
  },
  computed: {
    enabled: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    bgcolor() {
      return {
        background: this.getColorForMedId(this.id),
      };
    },
  },
  methods: {
    getColorForMedId(id) {
      return this.colors[id];
    },
  },
};
</script>
<style lang="scss" scoped>
  .medication {
    background: #F2E9FE;
    border-radius: 4px;
    color: $textCalendar;
    padding: .1rem .25rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-size: .9rem;

    &.disabled {
      text-decoration: line-through;
      background: $calHeaderBG !important;
      color: rgba($textCalendar, .5);
    }
  }
  .checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: .2rem;
  }

  .name {
    word-wrap: break-word;
    overflow-wrap: break-word;
    flex: 1;
  }

  .nurse-icon {
    background: $textCalendar;
    color: white;
    height: 1rem;
    width: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border-radius: 2px;
    opacity: .6;
  }
</style>
