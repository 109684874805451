<template>
  <div class="builder">
    <div class="header">
      <h1>{{planName}}</h1>
    </div>
    <Calendar v-model="calendarDays" :colors="medColorTable" :medlist="medlist"/>
    <Button @click="generateCalendar">Make PDF</Button>
  </div>
</template>
<script>
import Calendar from '@/components/calendar/Calendar.vue';
import CreateCalendarPDF from '@/utilities/CreateCalendarPDF';
import Button from '@/components/form/Button.vue';
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  components: {
    Calendar,
    Button,
  },
  setup() {
    const store = useStore();
    const calendarDays = computed({
      get() {
        return store.state.calendarDays;
      },
      set(value) {
        store.commit('setCalendarDays', value);
      },
    });

    const planName = computed(() => store.state.treatmentPlan.name);

    const medColorTable = computed(() => {
      const allMeds = [];
      calendarDays.value.forEach((day) => {
        day.medications.forEach((med) => {
          allMeds.push(med.id);
        });
      });

      const uniqueMeds = [...new Set(allMeds)];

      const colors = [
        '#FFCDD2', // Pink
        '#E1BEE7', // Purple
        '#BBDEFB', // Blue
        '#B2DFDB', // Green
        '#F0F3C3', // Yellow
        '#FFECB3', // Orange
        '#D7CCC8', // Brown
        '#DCEDC8', // Green 3
        '#FFCCBC', // Orange 2
        '#F8BBD0', // Pink
        '#D1C4E9', // Purple
        '#B3E5FC', // Blue
        '#C8E6C9', // Green 2
        '#FFF9C4', // Yellow
        '#FFE0B2', // Orange
        '#B3EBF2', // Blue
        '#C5CAE9', // Purple
      ];

      const colorTable = {};

      uniqueMeds.forEach((med, index) => {
        colorTable[med] = colors[index];
      });

      return colorTable;
    });

    const allMeds = computed(() => {
      const medArray = [];
      calendarDays.value.forEach((day) => {
        day.medications.forEach((med) => {
          medArray.push(med);
        });
      });

      const result = Array.from(new Set(medArray.map(m => m.id)))
        .map(id => medArray.find(s => s.id === id));

      return result;
    });

    const generateCalendar = () => {
      CreateCalendarPDF.CreateCalendar({
        antiViral: store.state.antiViral,
        bloodClot: store.state.bloodClot,
        preMeds: store.state.preMeds,
        patientId: store.state.patientId,
        regimen: store.state.regimen,
        startDate: store.state.startDate,
        treatmentPlan: store.state.treatmentPlan,
        calendarDays: store.state.calendarDays,
        allMeds: allMeds.value,
        preparedBy: store.state.user.name,
      }, {
        colors: medColorTable.value,
      });
    };

    return {
      calendarDays,
      medColorTable,
      generateCalendar,
      planName,
      allMeds,
    };
  },
};
</script>
<style lang="scss" scoped>
  .builder {
    max-width: $containerWidth;
    margin: 0 auto;
    padding: 1rem;
  }

  h1 {
    @include header;
    padding: 1rem 0 1rem 0;
  }
</style>
