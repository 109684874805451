<template>
  <div class="calendar">
    <div class="group">
      <CalendarSquare
        v-for="(day, index) in calendarDays"
        :key="day.dayNumber"
        v-model="calendarDays[index]"
        :colors="colors"
      />
    </div>
  </div>
</template>
<script>
import CalendarSquare from '@/components/calendar/CalendarSquare.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  components: {
    CalendarSquare,
  },
  props: {
    modelValue: Array,
    colors: Object,
  },
  setup(props) {
    const store = useStore();
    const calendarDays = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        store.$emit('update:modelValue', value);
      },
    });

    return {
      calendarDays,
    };
  },
};
</script>
<style lang="scss" scoped>
  .group {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-row-gap: 1rem;
    box-sizing: border-box;
  }
</style>
