<template>
  <div class="cal-square">
    <div class="header">
      Day {{day.dayNumber}}
    </div>
    <Draggable
      class="content"
      v-model="day.medications"
      :group="draggableGroup"
      :move="onDragEnd"
      ghostClass="placeholder"
      :clone="cloneMed"
      item-key="name"
    >
      <template #item="{element, index}">
        <MedicationItem
          :name="element.name"
          v-model="day.medications[index].enabled"
          :colors="colors"
          :nurse="element.givenByNurse"
          :id="element.id"
        />
      </template>
    </Draggable>
  </div>
</template>
<script>
import MedicationItem from '@/components/calendar/MedicationItem.vue';
import Draggable from 'vuedraggable';

export default {
  props: {
    modelValue: Object,
    colors: Array,
  },
  components: {
    MedicationItem,
    Draggable,
  },
  computed: {
    day: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    draggableGroup() {
      return {
        name: 'meds',
        pull: this.altPressed ? 'clone' : true,
      };
    },
  },
  data() {
    return {
      medications: [
        {
          name: 'Dexamethasone',
          enabled: true,
        },
        {
          name: 'Daratumumab IV',
          enabled: true,
        },
      ],
      altPressed: false,
    };
  },
  mounted() {
    document.addEventListener('keydown', this.onKeypress);
    document.addEventListener('keyup', this.onKeypress);
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.onKeypress);
    document.removeEventListener('keyup', this.onKeypress);
  },
  methods: {
    onKeypress(event) {
      this.$nextTick(() => {
        this.altPressed = event.altKey;
      });
    },
    onDragEnd(event) {
      let duplicate = false;
      event.relatedContext.list.forEach((med) => {
        if (med.name === event.draggedContext.element.name) {
          duplicate = true;
        }
      });
      return !duplicate;
    },
    cloneMed(original) {
      return { ...original };
    },
  },
};
</script>
<style lang="scss" scoped>
  .header {
    background: $calHeaderBG;
    padding: .1rem .5rem 0 .5rem;
    font-weight: bold;
    text-transform: uppercase;
    color: $textCalendar;
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }
  .content {
    border: 1px solid $calHeaderBG;
    padding: .5rem;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-gap: .25rem;
    box-sizing: border-box;
    //display: flex;
    //flex-direction: column;
  }
  .cal-square {
    height: 100%;
    box-sizing: border-box;
  }
</style>
<style lang="scss">
.placeholder {
  opacity: .4;
}
</style>
